import { useEffect, useLayoutEffect } from "react";
import { _getURL } from "@/api/index";
import Redux, { updatePath } from "@/action";
import { _getBalances } from "@/libraries/fund";
import Footer from "./footer";
// import Navbar from "./navbar";
import Header from "./header";

export default function Layout(props) {
    const { children } = props;
    const { t, session={} } = children.props;
    const { store, dispatch } = Redux();

    // useLayoutEffect(() => {
    //     function updateSize() {
    //         if (window.innerWidth < 1024) {
    //             location.reload();
    //         }
    //     }
    //     window.addEventListener('resize', updateSize);
    //     return () => window.removeEventListener('resize', updateSize);
    // }, []);
    
    return <>
        <Header {...children.props} />
        {/* <!-- main start here --> */}
        {children}
        {/* <!-- main ends here --> */}
        <Footer {...children.props}/>
    </>
}