
import React, { useState } from 'react';
import { _postURL } from '@/api/index';
import { useRouter } from "next/router";
import { Image } from '@mantine/core';
import { Link } from '../../helpers/common_helper';
import { motion } from 'framer-motion';
import { reduce } from 'lodash';

const headerMenu = [
    {name: "home", urlLink: ''},
    {name: "about", urlLink: 'about'},
    {name: "service", urlLink: 'service'},
    // {name: "portfolio", urlLink: 'portfolio'},
    {name: "contact", urlLink: 'contact'}
]

const animationHeaderHover_Text = {
    initial: { 
        color: "var(--secondaryColor)" ,
        scale: 1
    },
    hover: { 
        color: "var(--thirdColor)" ,
        scale: 1.1
    },
    tap: {
        color: "var(--thirdColor)" ,
        scale: 0.9
    }
}

export default function Index({ session, t }) {
    const { asPath, locale } = useRouter();
    const router = useRouter();
    return (
    <div className='header'>
        <div className='container'>
            <div className='header_inner'>
                <Link href={`/`}>
                    <Image
                        className='header_logo'
                        height={43}
                        width={160}
                        src="/assets/images/logo.png"
                        alt="Logo"
                        withPlaceholder
                    />
                </Link>
                <div className='header_right_box'>
                    {
                        reduce(headerMenu, (result, value, key) => {
                            result.push(
                                <Link key={key} href={`/` + value.urlLink}>
                                    <motion.div
                                        className='header_right_box_item'
                                        initial={"initial"}
                                        whileHover={"hover"}
                                        whileTap={"tap"}
                                    >
                                        <motion.span 
                                            className='header_menu_text'
                                            variants={animationHeaderHover_Text}
                                        >
                                            {value.name}
                                        </motion.span>
                                    </motion.div>
                                </Link>
                            )
                            return result;
                        }, [])
                    }
                </div>
            </div>
        </div>
    </div>
    )
    ;
}
