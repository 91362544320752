
import { copyrighted_name } from "@/config/site";
import { Link } from "@/helpers/common_helper";
import { Image } from '@mantine/core';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaPhoneAlt } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";

function Index(props) {
    let { t } = props;

    return (
        <footer className='footer'>
            <div className='container footer_inner'>
                <div className='footer_row'>
                    <div className='footer_icon_list'>
                    <a href="https://www.facebook.com/corebase.tech/" target="_blank" rel="noopener noreferrer">
                            <div className='icon_round'>
                                <FaFacebookF className='color_primary' size={25} />
                            </div>
                        </a>
                        <a href="https://www.linkedin.com/company/corebasetech/" target="_blank" rel="noopener noreferrer">
                            <div className='icon_round'>
                                <FaLinkedinIn className='color_primary' size={25} />
                            </div>
                        </a>
                        <a href="https://www.instagram.com/corebase.tech/" target="_blank" rel="noopener noreferrer">
                            <div className='icon_round'>
                                <FaInstagram className='color_primary' size={25} />
                            </div>
                        </a>
                    </div>
                </div>
                <div className='footer_row'>
                    <div className='mobile_footer_icon_list'>
                        <a href="https://www.facebook.com/corebase.tech/" target="_blank" rel="noopener noreferrer">
                            <div className='icon_round'>
                                <FaFacebookF className='color_primary' size={25} />
                            </div>
                        </a>
                        <a href="https://www.linkedin.com/company/corebasetech/" target="_blank" rel="noopener noreferrer">
                            <div className='icon_round'>
                                <FaLinkedinIn className='color_primary' size={25} />
                            </div>
                        </a>
                        <a href="https://www.instagram.com/corebase.tech/" target="_blank" rel="noopener noreferrer">
                            <div className='icon_round'>
                                <FaInstagram className='color_primary' size={25} />
                            </div>
                        </a>
                    </div>
                    <div>
                        <div className='color_primary footer_right_text'>
                           Copyright © 2021 Corebase Technologies All Rights Reserved.
                        </div>
                        <div className='color_primary footer_right_text'>
                            
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Index;
