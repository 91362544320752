import Redux, { updatePath } from "@/action"
import { useLayoutEffect, useEffect } from "react"
import { _getURL } from "@/api/index";
import { toast } from "@/libraries/swal";

export default function Layout({ children }) {
    const { store, dispatch } = Redux();
    const { t, session={} } = children.props;

    // useLayoutEffect(() => {
    //     function updateSize() {
    //         if (window.innerWidth >= 1024) {
    //             location.reload();
    //         }
    //     }
    //     window.addEventListener('resize', updateSize);
    //     return () => window.removeEventListener('resize', updateSize);
    // }, []);

    return <>{children}</>
}

