import FooterContent from '@/components/footer';



export default function Footer(props) {

    return <>
        <FooterContent {...props}/>
    </>
}
