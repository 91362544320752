import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import store, { persistor } from "@/store";
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { useEffect, useMemo, useState } from "react";
import Layout from "@/components/layout";
import Maintenance from "@/components/maintenance";
import MobileLayout from "@/components/mobile/layout";
import { MantineProvider } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { useTranslation } from 'next-i18next';
import { useRouter } from "next/router";
import _ from 'lodash';
import { _getURL } from "./api/index";
import "../../public/assets/css/main.css"

if (process.env.NEXT_PUBLIC_ENV !== 'dev') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0, //lower the value in production
  });
}

function MyApp({ Component, pageProps: { ...pageProps }, seo }) {
  const router = useRouter();
  let [state, setState] = useState({ device: null, isRunningAnalytics: false });

  const { t } = useTranslation('common');
  useEffect(async () => {
    window.history.prevUrl = [];
    setState({ device: window.innerWidth < 1024 ? "mobile" : "desktop" })
  }, [])

  const getLayout = Component.getLayout || null;
  const newProps = { ...pageProps, session: {}, device: state.device, t };

  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {() => (
        <>
          <Head>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <title>Corebase Technologies - Your IT Solutions Partner</title>
            <meta name="description"
              content="Corebase Technologies is your trusted partner for IT services. We specialize in web development, mobile app development, IoT integration, blockchain integration, Ecommerce and IT consulting. Explore our innovative solutions today."/>
              <meta name="keywords"
                content="Corebase Technologies, IT services, web development, mobile app development, IoT integration, blockchain integration, Ecommerce, IT consulting"/>

                <link rel="icon" href="/assets/images/logo/favicon.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="/favicon_io/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon_io/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon_io/favicon-16x16.png" />
                <link rel="manifest" href="favicon_io/site.webmanifest" />
              </Head>
              <MantineProvider>
                {
                  process.env.NEXT_PUBLIC_MAINTENANCE_MODE == 1 ?
                    <Maintenance />
                    : getLayout ?
                      getLayout(<Component {...newProps} />)
                      : state.device == "desktop" ?
                        <Layout>
                          <Component {...newProps} />
                        </Layout>
                        : state.device == "mobile" ?
                          <MobileLayout>
                            <Component {...newProps} />
                          </MobileLayout>
                          : <></>
                }
              </MantineProvider>
            </>
      )}
          </PersistGate>
        </Provider>
}

      export default appWithTranslation(MyApp)