import LinkNext from "next/link";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/react";
import { sys_currency } from "@/config/site"

export function Link({ href = "/", children, ...props }) {
    const route = useRouter();
    return <LinkNext href={"/"+route.locale + href} locale={false} {...props}>{children}</LinkNext>
}

export function log_message(msg) {
    let message = typeof msg === 'object' ? JSON.stringify(msg) : msg;
    Sentry.captureMessage(message);
}

export function getCurrency(){
    const route = useRouter();
    const locale = route.locale;
    let arr = locale.split('-');

    return sys_currency[arr[0]];
}

export function getLang(){
    const route = useRouter();
    const locale = route.locale
    let arr = locale.split('-');

    return arr[1];
}

export function errMsgHandling(err = {},default_msg = 'Error Occur'){
    let errJson = { message: default_msg };

    if(err.response && err.response.data && err.response.data.message){
        errJson.message = err.response.data.message;
    }
    else if(err.data && err.data.message){
        errJson.message = err.data.message;
    }
    else if(err.message){
        errJson.message = err.message;
    }
    else if(err && typeof err === 'string'){
        errJson.message = err;
    }

    return errJson;
}

export function kFormatter(num = 0){

    if(num > 9999 && num < 1000000){
        // convert to K for number from > 10000 < 1 million 
        return Math.sign(num)*((Math.abs(num)/1000)) + 'K';
    }else if(num > 999999){
        // convert to M for number from > 1 million 
        return Math.sign(num)*((Math.abs(num)/1000000)) + 'M';
    }else if(num < 9999){
        // return num; // if value < 01000, nothing to do
        return num;
    }
}

